export const privacyItems = [
  "A Finscore Tech criou o aplicativo Finscore como um aplicativo comercial. Este SERVIÇO é fornecido pela Finscore e destina-se ao uso como está. Esta página é usada para informar os visitantes sobre nossas políticas de coleta, uso e divulgação de Informações Pessoais, caso alguém decida usar nosso Serviço. Se você optar por  usar nosso Serviço, concorda com a coleta e uso de informações em relação a esta política. As Informações Pessoais que coletamos são usadas para fornecer e melhorar o Serviço. Não usaremos ou compartilharemos suas informações com ninguém, exceto conforme descrito nesta Política de Privacidade. Os termos usados nesta Política de Privacidade têm o mesmo significado que em nossos Termos e Condições, acessíveis em Finscore, a menos que definido de outra forma nesta Política de Privacidade.",
  "Quais informações coletamos sobre você",

  "Informações de conta e perfil Coletamos informações sobre você quando você se registra para uma conta, cria ou modifica seu perfil, define preferências, se inscreve ou faz compras por meio dos Serviços. Por exemplo, você fornece suas informações de contato quando se registra nos Serviços. Você também tem a opção de adicionar um nome de exibição, foto de perfil, cargo e outros detalhes às suas informações de perfil para serem exibidos em nossos Serviços. Acompanhamos suas preferências quando você seleciona configurações dentro dos Serviços.",
  "Conteúdo que você fornece por meio de nossos produtos",
  "Os Serviços incluem o Finscore, onde coletamos e armazenamos conteúdo que você publica, envia, recebe e compartilha. Este conteúdo inclui qualquer informação sobre você que você possa optar por incluir. Exemplos de conteúdo que coletamos e armazenamos incluem: o resumo e a descrição adicionados a uma ferramenta de comunicação, as páginas que você cria no Lean Portfolio, o nome de um quadro de Tarefas, seus repositórios e solicitações de recebimento na Biblioteca, quaisquer formulários que você cria em Tarefas e qualquer feedback que você nos forneça. O conteúdo também inclui os arquivos e links que você carrega nos Serviços. Se você usar uma versão de servidor ou data center dos Serviços, não hospedamos, armazenamos, transmitimos, recebemos ou coletamos informações sobre você (incluindo seu conteúdo), exceto em casos limitados, onde permitido pelo seu administrador: coletamos feedback que você fornece diretamente para nós através do produto e; e coletamos dados de fluxo de cliques sobre como você interage e usa recursos nos Serviços. Os administradores do servidor e do data center podem desativar nossa coleta dessas informações dos Serviços por meio das configurações do administrador ou impedir que essas informações sejam divulgadas para nós bloqueando a transmissão no nível da rede local.",
  "Conteúdo que você fornece por meio de nossos sites",
  "Os Serviços também incluem nossos sites de propriedade ou operados por nós. Coletamos outro conteúdo que você envia para esses sites, que incluem sites de mídia social ou redes sociais operados por nós. Por exemplo, você fornece conteúdo para nós quando fornece feedback ou quando participa de quaisquer recursos interativos, pesquisas, concursos, promoções, sorteios, atividades ou eventos.",
  "Outros serviços que você vincula à sua conta",
  "Recebemos informações sobre você quando você ou seu administrador integram aplicativos de terceiros, como Power-UPs, ou vinculam um serviço de terceiros aos nossos Serviços. Por exemplo, se você criar uma conta ou entrar nos Serviços usando suas credenciais do Google, recebemos seu nome e endereço de e-mail conforme permitido pelas configurações do perfil do Google para autenticá-lo. Você ou seu administrador também podem integrar nossos Serviços a outros serviços que você usa, como permitir que você acesse, armazene, divulgue e edite determinado conteúdo de um terceiro por meio de nossos Serviços. Por exemplo, você pode autorizar nossos Serviços a acessar, exibir e armazenar arquivos de um serviço de compartilhamento de documentos de terceiros dentro da interface dos Serviços. Ou você pode autorizar nossos Serviços a se conectar a um serviço de calendário de terceiros ou sincronizar uma lista de contatos ou agenda para que suas reuniões e conexões estejam disponíveis para você por meio dos Serviços, para que você possa convidar outras pessoas para colaborar com você em nossos Serviços ou para que sua organização possa limitar o acesso a determinados usuários. Seu administrador também pode autorizar nossos Serviços a se conectar a um serviço de relatório de terceiros para que sua organização possa revisar como os Serviços estão sendo usados. As informações que recebemos quando você vincula ou integra nossos Serviços a um serviço de terceiros dependem das configurações, permissões e política de privacidade controladas por esse serviço de terceiros. Você sempre deve verificar as configurações e avisos de privacidade nos serviços de terceiros para entender quais dados podem ser divulgados para nós ou compartilhados com nossos Serviços.",
  "Coleta e uso de informações",
  "Para uma melhor experiência, enquanto usa nosso Serviço, podemos exigir que você nos forneça certas informações pessoalmente identificáveis, incluindo, entre outras, nome, endereço de e-mail, data de nascimento, localização geográfica, informações de educação, informações de emprego, gênero, endereço IP, ID do cookie. As informações que solicitamos serão retidas por nós e usadas conforme descrito nesta política de privacidade.",
  "Como usamos as informações que coletamos",
  "Para fornecer os serviços e personalizar sua experiência nós usamos informações sobre você para fornecer os serviços a você, incluindo processar transações com você, autenticá-lo quando você faz login, fornecer suporte ao cliente e operar, manter e melhorar os serviços. Por exemplo, usamos o nome e a foto que você fornece em sua conta para identificá-lo para outros usuários do serviço. Nossos serviços também incluem recursos personalizados que personalizam sua experiência, aumentam sua produtividade e melhoram sua capacidade de colaborar efetivamente com outros, analisando automaticamente as atividades de sua equipe para fornecer resultados de pesquisa, feeds de atividades, notificações, conexões e recomendações que são mais relevantes para você e sua equipe. Por exemplo, podemos usar seu cargo declarado e atividade para retornar resultados de pesquisa que achamos relevantes para sua função. Também usamos informações sobre você para conectá-lo a outros membros da equipe que buscam sua experiência em determinado assunto. Podemos usar seu domínio de e-mail para inferir sua afiliação a uma determinada organização ou setor para personalizar o conteúdo e a experiência que você recebe em nossos sites. Onde você usa vários serviços, combinamos informações sobre você e suas atividades para fornecer uma experiência integrada, como permitir que você encontre informações de um serviço enquanto pesquisa em outro ou apresentar informações relevantes do produto enquanto você viaja pelos nossos sites. Com base em suas interações com diferentes produtos Finscore, personalizaremos sua experiência e adaptaremos nossas comunicações e ofertas para você. Para optar por sair dessa personalização, entre em contato com suporte@finscore.com.br.",
  "Para pesquisa e desenvolvimento:",
  "Estamos sempre procurando maneiras de tornar nossos serviços mais inteligentes, rápidos, seguros, integrados e úteis. Usamos informações e aprendizados coletivos (incluindo feedback) sobre como as pessoas usam nossos serviços para solucionar problemas, identificar tendências, padrões de uso, atividade e áreas para integração e melhorar nossos serviços e desenvolver novos produtos, recursos e tecnologias que beneficiem nossos usuários e o público. Por exemplo, para melhorar o recurso @menção, analisamos automaticamente as interações recentes entre usuários e com que frequência eles se mencionam para destacar as conexões mais relevantes para os usuários. Analisamos e agregamos automaticamente termos de pesquisa para melhorar a precisão e relevância dos tópicos sugeridos que aparecem automaticamente quando você usa o recurso de pesquisa. Em alguns casos, aplicamos esses aprendizados em nossos serviços para melhorar e desenvolver recursos semelhantes, integrar melhor os serviços que você usa ou fornecer insights com base em como outros usam nossos serviços. Também testamos e analisamos certos novos recursos com alguns usuários antes de lançar o recurso para todos os usuários.",
  "Para se comunicar com você sobre os serviços",
  "Nós usamos suas informações de contato para enviar comunicações transacionais por e-mail e dentro dos serviços, incluindo confirmação de suas compras, lembrando-o do vencimento de assinaturas, respondendo a seus comentários, perguntas e solicitações, fornecendo suporte ao cliente e enviando avisos técnicos, atualizações, alertas de segurança e mensagens administrativas. Nós enviamos notificações por e-mail quando você ou outros interagem com você nos serviços, por exemplo, quando você é mencionado em uma página ou ticket, quando uma tarefa é atribuída a você ou quando você é adicionado a um quadro de Tarefas. Dependendo do seu método de contato escolhido, podemos enviar notificações por SMS ou ligar para você se você estiver de plantão para alertas de incidentes. Também fornecemos comunicações personalizadas com base em sua atividade e interações conosco. Por exemplo, certas ações que você realiza nos serviços podem acionar automaticamente uma sugestão de recurso ou aplicativo de terceiros nos serviços que tornaria essa tarefa mais fácil. Também enviamos comunicações enquanto você se integra a um serviço específico para ajudá-lo a se tornar mais proficiente no uso desse serviço. Essas comunicações fazem parte dos serviços e, na maioria dos casos, você não pode optar por sair delas. Se uma opção de exclusão estiver disponível, você encontrará essa opção na própria comunicação ou nas configurações da sua conta.",
  "Suporte ao cliente",
  "Usamos suas informações para resolver problemas técnicos que você encontra, responder às suas solicitações de assistência, analisar informações de falhas e reparar e melhorar os serviços. Quando você nos dá permissão expressa para fazê-lo, divulgamos informações a um especialista terceirizado com o objetivo de responder a solicitações relacionadas ao suporte.",
  "Para segurança e proteção",
  "Usamos informações sobre você e seu uso dos serviços para verificar contas e atividades, detectar, prevenir e responder a incidentes de segurança potenciais ou reais e monitorar e proteger contra outras atividades maliciosas, enganosas, fraudulentas ou ilegais, incluindo violações das políticas de serviço.",
  "Com o seu consentimento",
  "Usamos informações sobre você quando você nos deu consentimento para fazê-lo para um propósito específico que não está listado acima. Por exemplo, podemos publicar depoimentos ou histórias de clientes em destaque para promover os serviços, com sua permissão.",
  "Seu uso dos serviços",
  "Mantemos o controle de certas informações sobre você quando você visita e interage com qualquer um de nossos serviços. Essas informações incluem os recursos que você usa; os links em que você clica; o tipo, tamanho e nomes de arquivos anexados que você carrega nos serviços; termos de pesquisa; e como você interage com os outros nos serviços. Também coletamos informações sobre as equipes e pessoas com as quais você trabalha e como trabalha com eles, como com quem você colabora e se comunica com mais frequência. Nós acompanhamos certas informações sobre você quando você visita e interage com qualquer um dos nossos serviços. Essas informações incluem os recursos que você usa; os links em que você clica; o tipo, tamanho e nomes de arquivos anexados que você faz upload nos serviços; termos de pesquisa; e como você interage com outros nos serviços. Também coletamos informações sobre as equipes e pessoas com quem você trabalha e como trabalha com elas, como com quem você colabora e se comunica com mais frequência.",
  "Dados de registro",
  "Queremos informar que sempre que você usar nosso serviço, em caso de erro no aplicativo, coletamos dados e informações (por meio de produtos de terceiros) em seu telefone chamados de dados de registro. Esses dados de registro podem incluir informações como o endereço de protocolo da internet (IP) do seu dispositivo, nome do dispositivo, versão do sistema operacional, configuração do aplicativo ao utilizar nosso serviço, hora e data do uso do serviço e outras estatísticas.",
  "Cookies",
  "Cookies são arquivos com uma pequena quantidade de dados que são comumente usados como identificadores exclusivos anônimos. Eles são enviados para o seu navegador a partir dos sites que você visita e são armazenados na memória interna do seu dispositivo. Este serviço não usa esses cookies explicitamente. No entanto, o aplicativo pode usar códigos e bibliotecas de terceiros que usam cookies para coletar informações e melhorar seus serviços. Você tem a opção de aceitar ou recusar esses cookies e saber quando um cookie está sendo enviado para o seu dispositivo. Se você optar por recusar nossos cookies, pode não conseguir usar algumas partes deste serviço.",
  "Provedores de serviços",
  "Podemos empregar empresas e indivíduos de terceiros devido aos seguintes motivos:\n\n· Facilitar nosso serviço;\n· Fornecer o serviço em nosso nome;\n· Realizar serviços relacionados ao serviço; ou\n· Nos ajudar a analisar como nosso serviço é usado.",
  "Queremos informar aos usuários deste serviço que esses terceiros têm acesso às suas informações pessoais. A razão é realizar as tarefas atribuídas a eles em nosso nome. No entanto, eles são obrigados a não divulgar ou usar as informações para qualquer outro propósito.",
  "Segurança",
  "Valorizamos sua confiança ao nos fornecer suas informações pessoais, por isso estamos nos esforçando para usar meios comercialmente aceitáveis ​​de protegê-las. Mas lembre-se de que nenhum método de transmissão pela internet ou método de armazenamento eletrônico é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.",
  "Links para outros sites",
  "Este serviço pode conter links para outros sites. Se você clicar em um link de terceiros, será direcionado para aquele site. Observe que esses sites externos não são operados por nós. Portanto, aconselhamos fortemente que você revise a Política de Privacidade desses sites. Não temos controle sobre o conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros.",
  "Privacidade de crianças",
  "Estes serviços não se destinam a menores de 13 anos. Não coletamos conscientemente informações de identificação pessoal de crianças menores de 13 anos. No caso de descobrirmos que uma criança menor de 13 anos nos forneceu informações pessoais, excluiremos imediatamente essas informações de nossos servidores. Se você é pai ou responsável e sabe que seu filho nos forneceu informações pessoais, entre em contato conosco para que possamos tomar as medidas necessárias.",
  "Alterações nesta política de privacidade",
  "Podemos atualizar nossa Política de Privacidade de tempos em tempos. Portanto, é aconselhável revisar esta página periodicamente para quaisquer alterações. Notificaremos você de quaisquer alterações postando a nova Política de Privacidade nesta página. Esta política é efetiva a partir de 01 de maio de 2024.",
  "Contate-Nos",
  "Se você tiver alguma dúvida ou sugestão sobre nossa Política de Privacidade, não hesite em nos contatar em comercial@finscore.com.br ou contato@finscore.com.br.",
];
